.code-container {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  
  .code-block {
    background-color: rgba(0, 0, 0, 0.76);
    color: white;
    padding: 10px;
    border-radius: 10px;
    overflow-x: auto;
    position: relative;
    font-family: 'Courier New', Courier, monospace;
  }

  .ql-syntax {
    background-color: rgba(0, 0, 0, 0.76);
    color: white;
    padding: 10px;
    border-radius: 10px;
    overflow-x: auto;
    position: relative;
    font-family: 'Courier New', Courier, monospace;
  }
  
  
  